.navbar{
	border: 0;
}

.btn, .form-control{
	border-radius: 0;
}

.panel{
	border: 0;
}