.ms-options-wrap,
.ms-options-wrap * {
    box-sizing: border-box;
}

.ms-options-wrap > button:focus,
.ms-options-wrap > button {
    position: relative;
    width: 100%;
    text-align: left;
    border: 1px solid $blue-border-color;
    background-color: #fff;
    padding: 5px 20px 5px 5px;
    margin-top: 1px;
    font-size: 13px;
    color: #aaa;
    outline: none;
    white-space: nowrap;
}

.ms-options-wrap > button > span {
    display: inline-block;
}

.ms-options-wrap > button[disabled] {
    background-color: #e5e9ed;
    color: #808080;
    opacity: 0.6;
}

.ms-options-wrap > button:after {
    content: ' ';
    height: 0;
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    border: 6px solid rgba(0, 0, 0, 0);
    border-top-color: #999;
    margin-top: -3px;
}

.ms-options-wrap > .ms-options {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 30px;
    margin-top: 1px;
    margin-bottom: 20px;
    background: white;
    z-index: 2000;
    overflow: auto;
    visibility: hidden;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2);
}

.ms-options-wrap > .ms-options.ms-active {
    visibility: visible
}

.ms-options-wrap > .ms-options > .ms-search{
    width: 80%;
    margin: 15px auto;
}

.ms-options-wrap > .ms-options > .ms-search input {
    width: 100%;
    padding: 4px 5px;
    outline: none;
    border: 1px solid $blue-border-color;
}

.ms-options-wrap > .ms-options .ms-selectall {
    display: inline-block;
    font-size: .9em;
    text-transform: lowercase;
    text-decoration: none;
}
.ms-options-wrap > .ms-options .ms-selectall:hover {
    text-decoration: underline;
}

.ms-options-wrap > .ms-options > .ms-selectall.global {
    margin: 4px 5px;
}

.ms-options-wrap > .ms-options > ul,
.ms-options-wrap > .ms-options > ul > li.optgroup ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.ms-options-wrap > .ms-options > ul li.ms-hidden {
    display: none;
}

.ms-options-wrap > .ms-options > ul > li.optgroup {
    padding: 5px;
}
.ms-options-wrap > .ms-options > ul > li.optgroup + li.optgroup {
    border-top: 1px solid $blue-border-color;
}

.ms-options-wrap > .ms-options > ul > li.optgroup .label {
    display: block;
    padding: 5px 0 0 0;
    font-weight: bold;
}

.ms-options-wrap > .ms-options > ul label {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 4px;
    margin-bottom: 0;
}

.ms-options-wrap > .ms-options > ul label:hover {
    background-color: $brand-primary;
    color: $light-text-color;
}

.ms-options-wrap > .ms-options > ul li.selected label {
}

.ms-options-wrap > .ms-options > ul input[type="checkbox"] {
    margin: 0 5px 0 0;
    position: absolute;
    right: 4px;
    top: 7px;
    opacity: 0;
}

.ms-options-wrap > .ms-options > ul input[type="checkbox"] + .checkbox-pretty{
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid $blue-border-color;
    position: absolute;
    right: 10px;
    top: 5px;
}

.ms-options-wrap > .ms-options > ul input[type="checkbox"]:checked + .checkbox-pretty:after{
    @extend .fa;
    content: "\f00c";
    position: absolute;
    right: -1px;
    top: 1px;
    font-size: 12px;
}

.ms-options-wrap > .ms-options > ul label:hover input[type="checkbox"] + .checkbox-pretty{
    border-color: $light-border-color;
}

.ms-options-wrap > .ms-options.hide-checkbox > ul input[type="checkbox"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}
