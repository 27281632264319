
// Body
$body-bg: #ededed;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;
$blue-border-color: #004080;
$light-border-color: #ffffff;
$outer-table-color: #f5f5f5;
$dark-border-color: #000;

// Backgrounds
$blue-active-bg: #004080;
$light-background: #fff;
$light-blue-background: #DEE9F3;

// Brands
$brand-primary: #0054a6;
$brand-info: #0081ff;
$brand-success: #8cc152;
$brand-warning: #ffa200;
$brand-danger: #ff0000;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;
$light-text-color: #ffffff;
$blue-text-color: #004080;

// Navbar
$navbar-default-bg: #0054a6;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #0054a6;
