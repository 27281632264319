
// Fonts
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,800');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome 
@import "font-awesome.min";

// Bootstrap resets
@import "resets";

// Searchable Multiselect
@import "jquery.multiselect";

$form-control-height: 42px;

html, body{
  height: 100%;
}

body{
	font-family: 'Fira Sans', sans-serif;
}

.blue-bg{
	color: #fff;
	background-color: $brand-primary;
}

.text-blue{
  color: $blue-text-color;
}

.no-margin{
	margin: 0;
}

.no-padding{
	padding: 0;
}

img{
    max-width: 100%;
}

label{
  font-weight: 500;
}

.form-control{
  border-color: $blue-border-color;
  height: $form-control-height;
}

.form-control-sidelines{
  border-right-width: 2px;
  border-left-width: 2px;
}

button.btn{
  height: $form-control-height;
}

.navbar{
  color: $light-text-color;
  h1{
    margin: 20px 0;
  }
}

.header-text>li{
  font-size: 2rem;
  text-transform: uppercase;
  padding: 12px 15px;
  letter-spacing: 1px;
  color: #004080;
  &:hover, &:focus, &:active{
    background-color: transparent;
  }
  img{
    @media(min-width: $screen-md-min){
      margin-right: 25px;
    }
  }
}

.logout-link{
  text-align: center;
  .fa{
    display: block;
  }
}

.notifications-link{
  position: relative;
  .badge{
    position: absolute;
    top: 20px;
    right: 3px;
    background-color: $brand-danger;
    color: $light-text-color;
    padding: 3px 4px;
    font-weight: 600;
  }

  +.dropdown-menu{
    width: 300px;
  }
}

.notification-item{
  padding: 0 15px;
}

.table{
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $outer-table-color;
  thead > tr > th,
  tbody > tr > td{
    padding: 15px;
    padding: 15px;
    color: $blue-text-color; 
  }
  thead{
    tr{
      th{
        font-size: 1.7rem;
        &.table-title{
          padding-top: 15px;
          padding-bottom: 15px;
          font-weight: 400;
        }
      }
    }
  }
  tbody{
    tr{
      td{
        font-weight: 500;
        font-size: 1.5rem;
        .label{
          margin-right: 5px;
        }
      }
    }
  }
  
  &.table-primary{
    thead{
      background-color: $light-background;
      tr{
        th{
          &.table-title{
            background-color: $blue-active-bg;
            color: $light-text-color;
          }
        }
      }
    }
    tbody{
      background-color: $light-background;
    }
  }

}

.table .fa-arrow-circle-down{
    font-size: 2rem;
}

div.dataTables_wrapper div.dataTables_paginate {
  text-align: center!important;
  margin-bottom: 15px;
}

#dataTable_filter{
  display: none;
}

.pagination{
  text-transform: uppercase;
}

.panel{
  &.panel-default{
    .panel-heading{
      background-color: $blue-active-bg;
      padding: 20px;
      .panel-title{
        color: $light-text-color;
        font-weight: 400;
        font-size: 1.7rem;
      }
    }
  }
}

.radio, .checkbox{
  margin-left: 5px;
  .fake-input{
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $dark-border-color;
    position: absolute;
    left: -4px;
  }

  input[type="checkbox"], input[type="radio"]{
    opacity: 0;
  }

  &.radio{
    .fake-input{
      border-radius: 50%;
    }

    input[type="radio"]{
      &:checked + span.fake-input:after{
        @extend .fa;
        content: "\f111";
        position: absolute;
        right: 5px;
        top: 4px;
        font-size: 10px;
      }
    }
  }

  &.checkbox{
    input[type="checkbox"]{
      &:checked + span.fake-input:after{
        @extend .fa;
        content: "\f00c";
        position: absolute;
        right: 1px;
        top: 1px;
        font-size: 16px;
      }
    }
  }
}

.text-blue{
  .fake-input{
    border-color: $blue-border-color;
  }
}

// Login
.login-box{
  margin-top: 150px;
  @media(max-width: $screen-sm-max){
    margin-top: 50px;
  }
  .panel-body{
    padding: 60px 15px 40px;
    @media(max-width: $screen-sm-max){
      padding: 40px 15px 30px;
    }
  }
  .form-group{
    margin-bottom: 20px;
    &.last-input{
      margin-bottom: 10px;
    }
  }

  .login-button{
    min-width: 100px;
    font-weight: 500;
    font-size: 2rem;
    letter-spacing: 1px;
  }
}

#wrapper{
  padding-left: 0;
}

.main{
  padding-top: 30px;
  @media(max-width: $screen-sm-max){
    margin-top: 60px;
  }
}

.questionnaire-buttons{
  margin-top: 30px;
}

.questionnaire-nav{
  li{
    width: 100%;
    +li{
      margin-left: 0;
    }
    a{
      background-color: $light-background;
      border-radius: 0;
      @include transition(all 200ms ease-in-out);
      a:not(.questionnaire-download){
        &:hover, &:active, &:focus{
          background-color: $blue-active-bg;
          color: $light-text-color;
        }
      }

      .label{
        width: 40px;
        height: 20px;
        display: block;
        max-width: 100%;
      }
    }

    a.questionnaire-download{
      background-color: transparent;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 2rem;
      &:hover, &:active, &:focus{
        background-color: transparent;
      }
    }
  }
}

.questionnaire-legend{
  @media(min-width: $screen-md-min){
    // position: absolute;
    // bottom: 12px;
  }
}

.pull-right-md{
  @media(min-width: $screen-md-min){
    float: right;
  }
}

.nav-tabs{
  border-color: $brand-primary;
  li{
    &.active{
      a{
        border-color: $brand-primary;
        border-radius: 0;
        border-bottom-color: $light-border-color;
        background-color: transparent;
        &:hover, &:focus{
          border-color: $brand-primary;
          background-color: transparent;
          border-bottom-color: $light-border-color;
        }
      }
    }
    a{
      &:hover{
        background-color: transparent;
      }
    }
  }
}

.form-builder{
  .radio, .checkbox{
    input{
      opacity: 1;
    }
  }
}

#build label{
  word-break: break-all;
}

#formtabs{
  li{
    a[href="#buttons"], a[href="#rendered"]{
      display: none;
    }
  }
}

.label-accepted,
.label-progress,
.label-waiting,
.label-rejected,
.label-language{
  &:empty{
    display: inline-block;
  }
  width: 40px;
  height: 20px;
  //display: inline-block;
  margin-right: 15px;
  position: relative;
  top: 3px;
}

.label-accepted{
  @extend .label-success;
}
.label-progress{
  @extend .label-info;
}
.label-waiting{
  @extend .label-warning;
}
.label-rejected{
  @extend .label-danger;
}
.label-language{
  background-color: #008080;
}

.sidebar-fullheight{
  position: relative;
}

.new-establissement{
  width: 100%;
  text-align: center;
  @media(min-width: $screen-md-min){
    position: absolute;
    bottom: 15px;
    left: 0;
  }
}

/* 
    Header right menu
*/
.header-menu{
  margin-right: 10px;
}
.header-menu li a{
  color: #0054A6;
}
.header-text li p{
  position: relative;
  display: block;
  padding: 15px 15px 0px 25px;
  color: #0054A6;
}

/* Sidebar Styles */
#sidebar-wrapper{
  background-color: #0054a5;
  position: fixed;
  width: 16.66666667%;
  height: 100%;
  top: 0; left: 0;
  @include transition(all 200ms ease-in-out);
  @media(max-width: $screen-sm-max){
    width: 80%;
    left: -80%;
    z-index: 99;
    top: 70px;
    &.in{
      left: 0;
    }
  }
}

.sidebar-brand{
  margin: 20px 15px 40px;
  text-align: center;
  a{
    display: inline-block;
    padding: 15px;
  }
}

.sidebar-nav {
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      border-top: 1px solid $light-border-color;
      &:last-child {
          border-bottom: 1px solid #fff;
      }
      a {
        display: block;
        text-decoration: none;
        color: $light-text-color;
        @include transition(background-color 200ms ease-in-out);
        padding: 15px;
        &:hover, &:active, &:focus{
          text-decoration: none;
          color: #fff;
          background: rgba(255,255,255,0.2);
        }
      }

      &.active{
          a{
              text-decoration: none;
              color: $light-text-color;
              background: $blue-active-bg;
          }
      }
    }
}

.block-heading{
  color: $blue-text-color;
  border-bottom: 2px solid $blue-border-color;
}

#form-saved, #form-error, #form-loading{
  display: none;
}

.input-error{
  color: tomato;
  border-color: tomato;
}

#content-wrapper{
    padding-bottom: 50px;
}

.header{
  background-color: $light-background;
  padding: 0 15px;
  height: 65px;
  z-index: 101;
  @media(max-width: $screen-sm-max){
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: 1px solid #ddd;
    height: 74px;
    .header-text{
      display: inline-block;
    }
  }

  .navbar-right{
    @media(max-width: $screen-sm-max){
      display: inline-block;
      float: right;
      margin-right: 40px;
      li{
        display: none;
        &:last-child{
          display: inline-block;
        }
      }
    }
  }

  #toggle_sidemenu{
    display: inline-block;
    background-color: #fff;
    border: 0;
    color: $blue-text-color;
    position: absolute;
    right: 10px;
    top: 20px;
    font-size: 2rem;
    @media(min-width: $screen-md-min){
      display: none;
    }
  }

}
.content{
    background-color: #fff;
    margin-top: 25px;
    margin-left: 20px;
    padding: 30px 10px;
}

.padding-left0{
    padding-left: 0px;
}
.no-radius{
    border-radius: 0px;
}

.ms-options-wrap > .ms-options{
  min-width: 300px;
}

.ms-options-wrap > button:focus, .ms-options-wrap > button{
  margin-top: 0;
  padding: 10px 20px 10px 10px;
  color: #333;
}

/*************************** 
    Forms 
*************************/
/* Index page */
.table-form{
    margin-bottom: 0px;
}
.forms-wrapper{
}
.forms-table{
    border: 1px solid #f1f1f1;
    border-radius: 7px;
    margin-bottom: 20px;
}

.table-title th{
    background-color: #f1f1f1;
    font-weight: normal;
}
.form-row{
    margin-left: 0px;
}
.form-row .btn{
    line-height: 0.8;
}

.send-form{
    margin-top: 35px;
}
.ui-autocomplete-category {
  font-weight: bold;
  padding: .2em .4em;
  line-height: 1.5;
}

.ui-autocomplete{
  display: none;
  background-color: #fff;
  width: 91%;
  max-height: 300px;
  overflow-y: scroll;
  list-style: none;
  padding-left: 0;
  border: 1px solid #269ECB;
  position: absolute;
  z-index: 3;
}
.ui-autocomplete div{
    background-color: #fff;
    color: #000;
}
.ui-autocomplete div ul{
    list-style: none;
    margin: 0 -.4em;
    padding: 0;
    font-weight: 400;
}
.ui-autocomplete div a{
    padding: 7px 10px;
    display: block;
    color: #000;
    transition: background-color 250ms, color 250ms ease-in-out;
    cursor: pointer;
}
.ui-autocomplete div a:hover, .ui-autocomplete div a:focus, .ui-autocomplete div a.active{
  text-decoration: none;
  background-color: $brand-primary;
  color: $light-text-color;
}
.ui-autocomplete ul li.child{
   border-bottom: 1px solid #e2dcdc; 
}
#share-form{
  margin-bottom: 15px;
  @media(max-width: $screen-sm-max){
    margin-top: 15px;
  }
}
#share-success{
    display: none;
}
#share-fail{
    display: none;
}

/*************************** 
    Profile settings
*************************/
.info-heading{
  font-weight: 400;
  letter-spacing: 1px;
}

.form-admin-profile{
  @media(min-width: $screen-md-min){
    margin-top: 150px;
  }
}


.form-admin-profile label{
  margin-top: 15px;
}

.form-admin-profile input{
    border-color: #004080;
    border-radius: 0px;
}

.form-admin-profile button{
    width: 41.66666667%;
    margin-left: 29.1666665%;
    @media(min-width: $screen-md-min){
      margin-top: 150px;
    }
}

.list-crud{
  padding-left: 0;
  li{
    list-style: none;
    overflow: hidden;
    margin-bottom: 15px;
    color: $blue-text-color;
    a, button{
      display: inline-block;
      margin-left: 5px;
      color: $text-color;
      line-height: 1.4;
      &:hover, &:focus, &:active{
        color: $text-color;
      }
    }
    button{
      background-color: transparent;
      border: 0;
      outline: none;
      padding: 0;
      &:hover, &:focus, &:active{
        outline: none;
      }
    }
  }
}

.fancy-upload{
  display: inline-block;
  padding: 10px 15px;
  font-size: 4rem;
  background-color: $brand-primary;
  color: $light-text-color;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
  +div.form-control{
    overflow: hidden;
    input[type="file"]{
      position: relative;
      // left: -103px;
      top: 2px;
    }
  }
}

/*************************** 
    Notifications
*************************/
.notifications{
    margin-top: 30px;
    border-radius: 14px; 
}
.notifications table .table-title th{
    background-color: #004080;
    color: #fff;
    padding: 10px;
}
.notifications table{
    overflow: hidden;
    background-color: #fff;
}
.notifications > .table:first-child{
    border-top-left-radius: 11px;
    border-top-right-radius: 12px;
}
.notifications > .table td{
    font-size: 13px;
}
.notifications > .table td.notif-message{
    font-size: 11px;
}
.notifications #notifications-table_paginate ul li:first-child a, 
.notifications #notifications-table_paginate ul li:last-child a {
    background-color: #004080;
    color: #fff;
}
.notifications #notifications-table_paginate ul li.active a {
    background-color: #004080;
    color: #fff;
}
.notifications .pagination li.previous a,
.notifications .pagination li.next a{
    border-color: #004080;
}
.notifications div.dataTables_wrapper div.dataTables_paginate{
    text-align: left;
}

.establissement-content .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #E0EAF4;
}
.establissement-content .table-striped > tbody > tr:nth-of-type(even) {
    background-color: #fff;
}

.establissement-form{
    margin-left: 30px;
}
.establissement-form label{
    font-weight: normal;
    color: #004080;
    font-style: italic;
}
.establissement-form input{
    border-color: #004080;
    color: #004080;
}
.establissement-form button{
    margin-top: 25px;
    width: 100%;
}
#newEstablissement .modal-header{
    border-bottom-color: #004080;
}
#newEstablissement .modal-header button{
    opacity: 1;
    color: #004080;
}
#newEstablissement .modal-header h4{
    color: #004080;
    font-weight: bold;
}

/*establissement-show*/
.establissement-image{
  position: relative;
  input[type=file]{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }
  input[type=file]+span.upload-wrapper{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-color: rgba(0,0,0,.5);
    color: $light-text-color;
    font-size: 3em;
    text-align: center;
    span{
      position: relative;
      top: 50%;
      margin-top: -20px;
      display: block;
    }
  }

  form{
    display: none;
    button{
      display: none;
    }
  }

  #enable-editing{
    position: absolute;
    display: inline-block;
    background-color: $light-background;
    color: $text-color;
    border-radius: 50%;
    padding: 10px;
    width: 40px;
    height: 40px;
    text-align: center;
    right: 10px;
    bottom: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05)
  }

  &.editable{
    form{
      display: block;
    }
    #enable-editing{
      display: none;
    }
  }

}

.establissement-show{

  .establissement-row{
    .col-sm-6:nth-of-type(odd){
      @media(min-width: $screen-md-min){
        padding-right: 10px;      
      }
    }

    .col-sm-6:nth-of-type(even){
      @media(min-width: $screen-md-min){
        padding-left: 10px;
      }
    }
  
    .box{
      margin-top: 20px;
      background-color: $light-background;
      p{
        color: #004080;
        padding: 15px 0px 15px 10px;
        margin-bottom: 0px;
        font-size: 12px;
        width: 100%;

        span{
            font-size: 13px;
        }
      }
    }

    &:nth-child(odd){
      >.col-sm-6:first-child{
        .box{
          background-color: $light-blue-background;
        }
      }
    }

    &:nth-child(even){
      >.col-sm-6:last-child{
        .box{
          background-color: $light-blue-background;
        }
      }
    }

  }

}

.establissement-show .followed{
  background-color: #fff;
  margin-top: 30px;
  @media(min-width: $screen-md-min){
    padding: 0 30px 30px;
  }

  .info-heading{
    font-weight: 500;
  }

  h5{
    margin-top: 10px;
    color: #004080;
    font-size: 14px;
    padding: 15px 0px;
  }

  table{
    margin-bottom: 0px;
  }
  
  .thematiques-box{
    @media(min-width: $screen-md-min){
      margin-right: 30px;
    }
    @media(max-width: $screen-sm-max){
      margin-bottom: 20px;
    }
    border: 1px solid #000;
    .table-hover > tbody > tr:hover{
      background-color: #004080;
    }
    .table-hover > tbody > tr:hover td{
      color: #fff;
    }
  }
  .steps-box{
    @media(min-width: $screen-md-min){
      margin-left: 30px;
    }
    border: 1px solid #000;
  }

  .table thead tr td{
    font-size: 10px;
  }

  #update-status{
    margin-top: 20px;
  }
}

#update-shared-form{
  margin-top: 20px;
}

.delete-entity-btn{
  margin-top: 60px;
}

.small-box{
  width: 12px;
  height: 12px;
  border: 1px solid #000;
  margin: 0 auto;
}
.followed .red{
    background-color: red;
}
.followed .orange{
    background-color: orange;
}
.followed .green{
    background-color: green;
}

.history{
    margin-top: 30px;
}
.history .table-title{
    background-color: #f1f1f1;  
}
.history .table-title th{
    font-size: 2rem;
    padding: 5px 15px;
    color: #000;
}
.history table thead tr th,
.history table tbody tr td{
  font-size: 1.4rem;
  color: #333;
}
.history table tbody tr td{
  font-weight: 400;
}

.history table tbody tr td label{
    font-weight: normal;
}
.history table .form-group{
    margin-bottom: 0px;
}
.history table .form-group label{
    margin-bottom: 0px;
}
.history .panel{
    margin-bottom: 0px;
}




.radio-awesome label{
    position: relative;
}

.radio-awesome input{
    visibility: hidden;
}

.radio-awesome label:before{
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #00f;
    left: -20px;
    top: 0;
}

.radio-awesome label:after{
    display: none;
    content: "";
    position: absolute;
    width: 4px;
    height: 4px;
    background: #00f;
    border-radius: 50%;
    top: 5px;
    left: -15px;
}

.radio-awesome input:checked + label:after{
    display: block;
}

.documents-history{
    margin-top: 30px;
}
.documents-history table{
    background-color: #fff;
}
.documents-history .table-title th{
    background-color: #004080;
    color: #fff;
    padding: 10px;
}
.documents-history table tr td.document-message{
    font-size: 12px;
}
.documents-history div.dataTables_wrapper div.dataTables_paginate{
    text-align: left;
    margin-left: -2%;
}

.documents-history #docs-history_paginate ul li a{
    color: #004080;
}
.documents-history #docs-history_paginate ul li:first-child a,
.documents-history #docs-history_paginate ul li:last-child a{
    background-color: #004080;
    color: #fff;
    border-color: #004080;
}
.documents-history #docs-history_paginate ul li.active a{
    background-color: #004080;
    color: #fff;
}


.dropdown-menu{
    width: 100%;
}

.add-thematic-etape .dropdown{
    width: 100%;
}

.dropdown .dropdown-toggle{
    width: 100%;
}

.add-thematic-etape{
  .btn{
    height: $form-control-height;
  }
}

/**************
    Users
***************/
#users-table{
    margin-top: 20px;
}

.user-edit .alert{
    margin-top: 10px;
}

.user-edit form{
    margin-top: 20px;
}



/****************** 
    Client
*******************/
.client-profile{
    margin-top: 0px;
}
.client-profile button{
    margin-top: 30px;
}

.documents-client{
  label{
    font-weight: 500;
    font-size: 16px;
    color: #004080;
    letter-spacing: 1px;
  }

  .search-input{
    label{
      margin-top: 8px;    
    }
  }

}

.docs-client-table table{
    margin-top: 20px;
}

.docs-client-table table th{
    font-weight: normal;
    color: #004080;
}
.docs-client-table table thead tr:first-child th{
    font-weight: normal;
    color: #000;
    background-color: #ddd;
}

.docs-client-table #docs-client_paginate ul li:first-child a, 
.docs-client-table #docs-client_paginate ul li:last-child a {
    background-color: #004080;
    color: #fff;
}
.docs-client-table #docs-client_paginate ul li.active a {
    background-color: #004080;
    color: #fff;
}
.docs-client-table .pagination li.previous a,
.docs-client-table .pagination li.next a{
    border-color: #004080;
}

table#docs-client{
  tbody{
    tr{
      td:last-child{
        text-align: center;
      }
    }
  }
}

.page-reload-btn{
  @media(min-width: $screen-md-min){
    float: right;
  }
}


#questionnaire-history{
  tbody{
    tr{
      td{
        color: #fff;
        a{
          color: #fff;
        }
        .radio-awesome{
          label:before{
            border-color: #fff;
          }
          label:after{
            background-color: #FFF;
          }
        }
      }
    }
  }
}

.form-components{
  position: fixed;
  left: calc(54.861111112% + 45px);
  width: calc(38.194444442% - 60px);
}


/********************* 
    Send document
*********************/
// .send-doc{
//     width: 80%;
//     margin-left: 10%;
// }
// .send-doc p{
//     color: #004080;
//     margin-top: 10px;

// }
// .send-doc label{
//   color: #004080;
//   font-weight: normal;
// }
// .send-doc button{
//     line-height: 1;
// }

// .docs-sent{
//     margin-top: 30px;
//     margin-left: 5px;
// }
// .docs-sent table{
//     background-color: #fff;
// }
// .docs-sent table th{
//     font-weight: normal;
// }
// .docs-sent table thead tr:first-child th{
//     font-weight: normal;
//     color: #fff;
//     background-color: #004080;
// }

// .docs-sent #sent-docs-table_paginate ul li:first-child a, 
// .docs-sent #sent-docs-table_paginate ul li:last-child a {
//     background-color: #004080;
//     color: #fff;
// }
// .docs-sent #sent-docs-table_paginate ul li.active a {
//     background-color: #004080;
//     color: #fff;
// }
// .docs-sent .pagination li.previous a,
// .docs-sent .pagination li.next a{
//     border-color: #004080;
// }
